import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import CloseIcon_ from "./icon-close.svg";
import PhoneIcon_ from "./icon-phone.svg";
export { default as ArrowIcon } from "./icon-arrow.svg";

export const CloseIcon = styled(CloseIcon_)``;
export const PhoneIcon = styled(PhoneIcon_)``;

export const Message = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-family: ${fontRoboto};
  line-height: 21px;
  white-space: nowrap;
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  inline-size: 100vw;
  max-inline-size: 334px;
  margin: auto;
  padding: 14px;

  border-radius: 3px;

  background-color: #4585ff;

  cursor: pointer;

  > * {
    flex: none;
  }

  ${CloseIcon} {
    position: absolute;
    inset-block-start: -12px;
    inset-inline-end: -12px;
  }

  ${PhoneIcon} {
    margin-inline: auto 16px;
  }
`;
