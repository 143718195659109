import isString from "lodash/isString";
import { useRouter } from "next/router";
import type { CustomContentProps } from "notistack";
import { SnackbarContent } from "notistack";
import { forwardRef } from "react";

import { ArrowIcon, CloseIcon, Content, Message, PhoneIcon } from "./styles";

export type AppPromotionSnackbarItemProps = { onClickClose?: () => void };
export type Props = AppPromotionSnackbarItemProps & CustomContentProps;

export const AppPromotionSnackbarItem = forwardRef<HTMLDivElement, Props>(
  ({ onClickClose }, reference) => {
    const router = useRouter();
    const handleClick = () => {
      const oneLinkArticle = new URL("https://scmp.onelink.me/3586748601/thaczxeh");
      oneLinkArticle.searchParams.set("deep_link_value", window.location.pathname);
      if (isString(router?.query?.share)) {
        oneLinkArticle.searchParams.set("share", router.query.share);
      }
      window.location.href = oneLinkArticle.toString();
    };

    const handleClose = () => {
      onClickClose?.();
    };

    return (
      <SnackbarContent ref={reference} role="banner">
        <Content onClick={handleClick}>
          <Message>
            Try this article in our <br />
            <b>mobile app</b>
          </Message>
          <PhoneIcon />
          <ArrowIcon />
          <CloseIcon onClick={handleClose} />
        </Content>
      </SnackbarContent>
    );
  },
);

AppPromotionSnackbarItem.displayName = "AppPromotionSnackbarItem";
