import type { SlideProps } from "@mui/material";
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";
import type { FunctionComponent, ReactNode } from "react";

import { AppPromotionSnackbarItem } from "./app-promotion-item";
import { SimpleSnackbarItem } from "./simple-snackbar-item";

export type Props = {
  children?: ReactNode;
};

export const SnackbarProvider: FunctionComponent<Props> = ({ children }) => (
  <NotistackSnackbarProvider
    Components={{
      appPromotion: AppPromotionSnackbarItem,
      simple: SimpleSnackbarItem,
    }}
    maxSnack={1}
    TransitionProps={
      {
        direction: "up",
      } as SlideProps
    }
  >
    {children}
  </NotistackSnackbarProvider>
);

SnackbarProvider.displayName = "SnackbarProvider";
